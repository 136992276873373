import { useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { Container, Col, Row, defaultTheme as theme, Button } from '@geberit/gdds';

// styles
import styles from './tiles.module.scss';

// types
import type { Tile } from './tleb1-tiles-props';

// components
import Tleb1Tiles from './tleb1-tiles';
import { SectionClosingLine } from '../Common/section-closing-line';
import { Headline, Formats, getFontWeight } from 'components/ContentElements/Headline';
import VideoLightbox from 'components/LightBox/VideoLightbox';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { clickTileLink } from 'components/ContentElements/Teaser/trackingActions';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useTracking } from 'utils/hooks/useTracking';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useNord } from 'utils/hooks/use-nord';
import { carouselGridSize, gridSize } from './sizes';
import { useIsDesktop, useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { useThemeName } from 'utils/hooks/use-theme';
import { useSectionId } from 'utils/hooks/use-section-id';
import { useTranslationByKey } from 'utils/hooks/use-translations';

export interface Props {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  type: string;
  items?: any[];
  isLargeDesktop?: boolean;
  background?: string;
  backgroundColor?: string;
  title?: string;
  subtitle?: string;
  emphasize?: string;
  contentIndex?: number;
  contentLength?: number;
  carousel?: boolean;
  showmore?: boolean;
  sizes: {
    mobile: number;
    tablet: number;
    desktop: number;
  };
  selectMode?: string;
  anchor?: string;
  text?: string;
  titleInAnchor?: boolean;
  aspectRatio?: string;
  border: unknown;
  colorVariant: 'bg-white' | 'bg-porcelain' | 'bg-wave' | 'bg-sky' | 'bg-norway' | 'bg-stone';
}

export function ProductiveTiles({
  items = [],
  background = '',
  backgroundColor = '',
  title,
  subtitle,
  emphasize,
  contentIndex,
  contentLength,
  text,
  carousel,
  showmore,
  type,
  sizes,
  selectMode,
  border,
  colorVariant,
  anchor,
  titleInAnchor,
  aspectRatio,
}: Readonly<Props>) {
  const isDesktop = useIsDesktop();
  const isLargeDesktop = useIsDesktop({ gdds: true });
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const track = useTracking();
  const [modalOpen, setModalOpen] = useState(false);
  const themeName = useThemeName();
  const isNord = useNord();
  const format = isNord ? Formats.h2 : Formats.h1;
  const isContactSection = type === 'contact_tiles';
  const loadMoreText = useTranslationByKey('web20_downloadcenter_more_results');
  const backgroundParam = background || backgroundColor || 'bg-white';
  const tileBackground = backgroundParam === 'white' ? 'light' : 'white';

  const sectionId = useSectionId(title ?? '', anchor ?? '', Boolean(titleInAnchor), contentIndex);

  const loadableTiles = useMemo(() => {
    let tileSize: number | undefined;
    if (isMobile) {
      tileSize = sizes?.mobile;
    } else if (isTablet) {
      tileSize = sizes?.tablet;
    } else tileSize = sizes?.desktop;

    return tileSize;
  }, [isMobile, isTablet, sizes]);

  const initTiles = () => {
    if (!isEmpty(items)) {
      return !carousel && showmore ? items.slice(0, loadableTiles) : items;
    }
    return [];
  };

  const [tiles, setTiles] = useState(initTiles);

  const trackLink = (item: Tile) => {
    track.trackEvent(clickTileLink(window.location.href, item.link?.target));
  };

  const toggleVideoModal = (video) => {
    setModalOpen(video);
  };

  const isLastSection = (contentLength ?? 1) - 1 === contentIndex;

  if (isEmpty(items) || (selectMode === 'topics' && items.length < 2)) return null;

  const gridWidth =
    ((isDesktop && !isNord) || (isNord && isLargeDesktop)) && carousel && tiles.length > 3
      ? carouselGridSize[themeName ?? 'GDDS']
      : gridSizes[gridSize[themeName ?? 'GDDS']];

  return (
    <>
      <div
        className={classNameBuilder(
          styles.productiveWrapper,
          styles[backgroundParam],
          isLastSection && styles.isLast,
          colorVariant ? styles[colorVariant] : '',
          !border ? styles.border : '',
        )}
        id={sectionId}
      >
        {(title || subtitle || text) && (
          <Container maxContentWidth={buildSize(isNord ? gridSizes.gddsFullGrid : gridSizes.full)}>
            <Row>
              {isDesktop && !carousel && isNord && <Col size={[4, 8, 2]} />}
              <Col size={[4, 8, !carousel && isNord ? 8 : 12]}>
                {(title || subtitle) && (
                  <Headline
                    format={format}
                    title={title}
                    subtitle={subtitle}
                    tag={Formats.h2}
                    titleFontWeight={getFontWeight(emphasize === 'title', themeName, format)}
                    subtitleFontWeight={getFontWeight(emphasize === 'subtitle', themeName, format)}
                    className={styles.headline}
                    titlePreviewId="#st_title"
                    subtitlePreviewId="#st_subtitle"
                  />
                )}
                {text && (
                  <InnerHtml
                    content={text}
                    isGdds
                    as="p"
                    className={isNord ? styles.pFull : undefined}
                  />
                )}
              </Col>
            </Row>
          </Container>
        )}

        <InlineEdit
          previewId={(function () {
            if (selectMode === 'manual' || isNord) {
              return '#st_tiles';
            }
            if (isContactSection) {
              return '#st_contacts';
            }
            return undefined;
          })()}
        >
          <Container maxContentWidth={buildSize(gridWidth)} className={styles.tilesWrapper}>
            {!isEmpty(tiles) && (
              <Row>
                <Tleb1Tiles
                  tiles={tiles}
                  isMobile={isMobile}
                  toggleVideoModal={toggleVideoModal}
                  trackLink={trackLink}
                  isContactSection={isContactSection}
                  tileBackground={tileBackground}
                  carousel={carousel}
                  {...(isNord ? { aspectRatio } : {})}
                  {...(isNord ? { colorVariant } : {})}
                />
              </Row>
            )}
            {!carousel && showmore && tiles?.length < items?.length && (
              <Row className={styles.buttonRow}>
                <Button
                  symbol="Reload"
                  className={styles.loadmoreIcon}
                  stylingType="secondary"
                  color={theme.colors.grey13}
                  onClick={() => {
                    setTiles(items.slice(0, tiles?.length + loadableTiles));
                  }}
                >
                  {loadMoreText}
                </Button>
              </Row>
            )}
          </Container>
        </InlineEdit>
        {!border && <SectionClosingLine />}
      </div>
      {modalOpen && (
        <VideoLightbox
          onClose={toggleVideoModal}
          videoTitle={modalOpen?.title}
          videoId={modalOpen.video?.videoId}
        />
      )}
    </>
  );
}
