import { Container, Row, Col, Divider } from '@geberit/gdds';

// styles
import styles from './border.module.scss';

// utils
import { buildSize, gridSizes } from 'utils/gridSize';
import { useNord } from 'utils/hooks/use-nord';
import { classNameBuilder } from 'utils/classNameBuilder';

interface SectionClosingLineProps {
  withBottomMargin?: boolean;
}

export function SectionClosingLine({
  withBottomMargin = false,
}: Readonly<SectionClosingLineProps>) {
  const isNord = useNord();

  return (
    <Container maxContentWidth={buildSize(gridSizes[isNord ? 'gddsFullGrid' : 'full'])}>
      <Row>
        <Col
          size={[4, 8, 12]}
          className={classNameBuilder(styles.border, withBottomMargin && styles.withBottomMargin)}
        >
          <Divider alignment="horizontal" />
        </Col>
      </Row>
    </Container>
  );
}
