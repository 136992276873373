import { Children } from 'react';
import striptags from 'striptags';
import { Col, ExpressiveTile, CarouselSSRWrapper } from '@geberit/gdds';

// styles
import styles from './tiles.module.scss';

// types
import type { Tleb1TilesProps } from './tleb1-tiles-props';
import type { Colors } from '../CustomGdds/Tiles/src/tile/tile.types';

// components
import Contact from '../Contact/contact';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { CmsLink } from 'components/Link/CmsLink';

// utils
import { classNameBuilder as buildClassName } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';
import { useNord } from 'utils/hooks/use-nord';
import { useKolo } from 'utils/hooks/use-kolo';
import { useIsCC } from 'utils/hooks/use-is-cc';
import { usePreviewIdBuilder } from 'utils/preview-id-builder';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { useTwyford } from 'utils/hooks/use-twyford';

function Tleb1Tiles({
  tiles,
  isMobile,
  toggleVideoModal,
  trackLink,
  tileBackground,
  isContactSection,
  aspectRatio,
  colorVariant,
  carousel,
}: Readonly<Tleb1TilesProps>) {
  const isNord = useNord();
  const isKolo = useKolo();
  const isCC = useIsCC();
  const isTwyford = useTwyford();
  const isPreview = useIsPreview();
  const previewIdBuilder = usePreviewIdBuilder();

  if (isKolo && colorVariant !== 'bg-porcelain') {
    tileBackground = 'white';
    colorVariant = 'bg-white';
  }

  if (isTwyford && !(colorVariant === 'bg-porcelain' || colorVariant === 'bg-white')) {
    tileBackground = 'white';
    colorVariant = 'bg-white';
  }

  const tileWithBorder = isNord && tileBackground === 'white' && colorVariant === 'bg-white';

  const elementsToRender = tiles?.map((item, index) => {
    const image = (isMobile && item.imageObjectMobile?.url) || item.imageObject?.url || item.image;
    let videoImage;

    if (!image) {
      videoImage = isMobile
        ? item.video?.videoPreviewImage?.mobile
        : item.video?.videoPreviewImage?.desktop;
    }

    const getRatio = () => {
      const ratio = isNord
        ? aspectRatio
        : (isMobile && item.imageObjectMobile?.aspectRatio) || item.imageObject?.aspectRatio;

      return ratio?.replace('_', ':');
    };

    const paragraph = isContactSection ? (
      <Contact {...item} isTile isPreview={isPreview} />
    ) : (
      <>
        <InnerHtml
          previewId={item.previewId ? '#st_text' : undefined}
          content={item.text}
          as="div"
        />
        <CmsLink
          tracking={() => trackLink(item)}
          link={{ ...item.link, text: decodingContent(item.link?.text) }}
          standardFontSize={false}
          alignByContent={item.link?.showArrow && 'left'}
        />
      </>
    );

    const noImageBorder = isNord && !image && !videoImage && colorVariant === 'bg-white';

    return (
      <div
        key={item.title}
        className={buildClassName(
          isContactSection ? styles.contact : styles.productiveTile,
          tileWithBorder && styles.tileBorder,
          noImageBorder && styles.noImageBorder,
          styles.tileWrapper,
        )}
        data-preview-id={previewIdBuilder((item.previewId || isContactSection) && `#${index}`)}
      >
        <ExpressiveTile
          {...item}
          type="tleb1"
          image={image || videoImage}
          imageTitle={item.imageAlt}
          altText={item.imageAlt}
          link={{}}
          headlineFontSize={isNord ? 'h4' : 'h7'}
          fontSize="p1"
          imageAspectRatio={getRatio()}
          title={decodingContent(striptags(item.title || item.name))}
          customBackgroundColor={tileBackground as Colors}
          showPlayButton={!image && videoImage}
          linkStyleType="secondary"
          titlePreviewId={(function () {
            if (isContactSection) {
              return '#tt_name';
            }
            if (isNord) {
              return '#st_headline';
            }
            return undefined;
          })()}
          customParagraph={paragraph}
          {...(!image && videoImage ? { onEvent: () => toggleVideoModal(item) } : {})}
          {...{ isPreview: isPreview }}
        />
        {item.isAutofilled && isCC && (
          <CmsLink
            link={{ ...item.link, showArrow: false, text: '', window: '_self' }}
            className={styles.autofilled}
          />
        )}
      </div>
    );
  });

  const shouldRenderCarousel = carousel && tiles?.length > 3;
  const nordProps = {
    hideButtonsTablet: true,
    slideMargin: 24,
  };

  if (shouldRenderCarousel) {
    return (
      <Col size={[4, 8, 12]} className={styles.carouselWrapper}>
        <CarouselSSRWrapper hideButtons supportMouse {...(isNord && nordProps)}>
          {elementsToRender}
        </CarouselSSRWrapper>
      </Col>
    );
  }

  return Children?.map(elementsToRender, (item) => (
    <Col size={[4, 4, 4]} className={styles.tile}>
      {item}
    </Col>
  ));
}

export default Tleb1Tiles;
